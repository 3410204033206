import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import ReactToPrint, { PrintContextConsumer } from 'react-to-print'
import { refreshToken } from './refresh.js';

import Logo from './logo.js';

import withStyles from '@material-ui/styles/withStyles';
import {
  AppBar,
//  Avatar,
  Box,
  CircularProgress,
  Dialog,
  Divider,
  Grid,
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Slide,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
} from '@material-ui/core'


import { DataGrid } from '@mui/x-data-grid'

import CloseIcon from '@material-ui/icons/Close'
import LaunchIcon from '@material-ui/icons/Launch';
import ReceiptLongIcon from '@material-ui/icons/ReceiptLong';

import axios from 'axios'

import { green, grey, red } from '@material-ui/core/colors'


const AUTHORIZE = 1
const INVOICE = 2


const WITHPICS = 1
const WITHOUTPICS = 0

const styles = (theme) => ({
  a: {
    fontSize: '13pt',
    color: '#fff',
    padding: '10px 20px',
    textAlign: 'center',
    textDecoration: 'none',
    background: '#e597ba',
    borderRadius: '8px',
    position: 'absolute',
    right: 10,
  },
  article0: {
    background: 'white',
    color: 'black',
    fontSize: '14pt',
    marginLeft:'80px',
    maxWidth: 1000,
    padding: '55px 15px 10px 15px',
    width: '90%',
  },
  article1: {
    background: 'white',
    color: 'black',
    fontSize: '13pt',
    marginLeft:'80px',
    maxWidth: 1000,
    padding: '30px 15px 20px 15px',
    width: '90%',
  },
  article2: {
    background: 'white',
    border: '1px solid #4d4e51',
    color: 'black',
    fontSize: '13pt',
    marginLeft:'80px',
    marginTop: '20px',
    maxWidth: 1000,
    padding: '10px 15px 0px 15px',
    width: '90%',
  },
  article3: {
    border: '1px solid #5a5d63',
    color: 'black',
    fontSize: '13pt',
    marginTop: '15px',
    marginLeft:'80px',
    maxWidth: 1000,
    padding: '5px 15px 5px 15px',
    width: '90%',
  },  
  article4: {
    background: 'white',
    color: 'black',
    fontSize: '13pt',
    marginLeft:'80px',
    maxWidth: 1000,
    padding: '50px 15px 5px 15px',
    width: '90%',
  },
  b: {
    background: '#e597ba',
    border: '1px solid #4d4e51',
    borderRadius: '8px',
    color: '#fff',
    padding: '10px',
    fontSize: '14pt',
    textAlign: 'center',
    '&:hover': {
      backgroundColor: '#ffbfdb',
      color: '#3c52b2',
      cursor: 'pointer'
    },    
    position: 'absolute',
    right: 10,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  dateString: {
    textAlign: 'end',
    fontSize: '13pt',
  },
  divider: {
    border: '1px solid #000000',
    color: '#000000',
  },
  error: {
    fontSize: '20px',
    color: red[400],
  },
  finePrint: {
    fontSize: '11pt',
    color: 'black',
  },
  finePrint2: {
    fontSize: '11pt',
    color: 'black',
  },
  form: {
    width: '90%',
    marginLeft: 13,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    fontSize: 14,
  },
  imageContainer: {
    marginTop: '5rem',
    display: 'block',
    pageBreakBefore: 'always',
  },
  imageRoot: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  imageList: {
    marginLeft:'60px',
    width: 1000,
    height: 1500,
  },
  poptitle: {
    marginLeft: theme.spacing(2),
    width: 800,
  },
  pos: {
    marginBottom: 12,
  },
  printButton: {
    //		position: 'fixed',
    bottom: 0,
    left: 0,
    color: green
  },
  prodGrid: {
    color: grey[900],
    background: '#edf0f7',
  },
  prodGridInv: {
    width: 1000,
    '& .MuiDataGrid-columnHeaders': {
      backgroundColor: "#2b4287",
      color: "#f4f7ff",
      fontSize: 15  },
  },
  root: {
    minWidth: 470,
  },
  selectButton: {
    //		position: 'fixed',
    bottom: 0,
    right: 0,
  },
  subTitle: {
    fontSize: '20px',
    width: 400,
    color: grey[700],
    margin: '54px 0px 0px 0px',
    // ConsultUs
    background: 'linear-gradient(45deg, #EEE 30%, #DDD 90%)',
    // Wound Care
    // background: 'linear-gradient(45deg, #f2f0ea 30%, #b3acc1 90%)',
    borderRadius: '4px',
  },
  // subTitle: {
  //   fontSize: '20px',
  //   width: 430,
  //   color: grey[700],
  //   top: 20,
  //   bottom: 20,
  //   // ConsultUs
  //   background: 'linear-gradient(45deg, #EEE 30%, #DDD 90%)',
  //   // SummerApp
  //   // background: 'linear-gradient(45deg, #f2f0ea 30%, #bcb2a3 90%)',
  //   borderRadius: '4px',
  // },
  table: {
    marginLeft:'60px',
    maxWidth: 1000,
    background: '#edf0f7',
  },
  toolbar: theme.mixins.toolbar,
  uiProgess: {
    position: 'fixed',
    zIndex: '1000',
    height: '31px',
    width: '31px',
    left: '50%',
    top: '35%',
  },
  upAndBold: {
    textAlign: 'left',
    fontSize: '15pt',
    fontWeight: 'bold',
  },
  upAndLeft: {
    textAlign: 'left',
    fontSize: '12pt',
  },
  upAndRight: {
    fontSize: '12pt',
    textAlign: 'end',
  },
  upAndUnderline: {
    fontSize: '15pt',
    fontWeight: 'bold',
    textAlign: 'end',
    textDecoration: 'underline'
  },
  visitGrid: {
    color: grey[900],
    // ConsultUs
    background: 'linear-gradient(45deg, #e5eae8f0 30%, #afc9bed0 90%)',
    // SummerApp
    // background: 'linear-gradient(45deg, #ccc1b1 30%, #eeeae3 90%)'
  },
})

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" {...props} ref={ref} />
))

const assessment0 = {
  allergies: '',
  balance: 'unknown',
  comorbidities: '',
  depth: 1,
  education: '',
  exudateLevel: '1',
  exudateType: '',
  healingFactors: '',
  healthEducation: '',
  infection: false,
  inflammation: false,
  length: 1,
  margin: 'unknown',
  medInfo: '',
  medications: '',
  mobility: '',
  nutrition: '',
  painLevel: 0,
  postsurgical: false,
  site: '',
  smoking: '',
  treatment: '',
  treatmentPlan: '',
  type: '',
  viability: 'unknown',
  visitId: '',
  weight: 0,
  width: 1,
}

function formatNumber(number)
{
    number = number.toFixed(2) + ''
    let x = number.split('.')
    let x1 = x[0]
    let x2 = x.length > 1 ? '.' + x[1] : ''
    let rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
      x1 = x1.replace(rgx, `$1,$2`)
    }
    return x1 + x2
}

class authorize extends Component {
  constructor(props) {
    super(props)

    this.state = {
      patient: {
        firstName: 'Bami',
        gender: 'Ms.',
        mobile: '082',
        medicalAid: 'Fedhealth',
        memberNo: '111',
        patientName: 'Goreng',
        referringDoctor: 'Dr. Who',
        referringDrNo: '111',
        SAID: '111',
      },
      assessment: {},
      buttonType: '',
      createOpen: false,
      dateString: 'dateString',
      editOpen: false,
      error: false,
      fieldname: '',
      fieldvalue: null,
      filter: '',
      fullName: 'Ms. Bami Goreng',
      images: [],
      login: false,
      paLoading: false,
      printAuthOpen: false,
      printClicked: false,
      printInvoiceOpen: false,
      prodList: [],
      products: [],
      sbLoading: true,
      selectedRows: [],
      selectVisitOn: false,
      stockItems: [],
      supplRows1: [],
      totals: 0,
      treatmentDates: [],
      treatCodeString : '88002, 88041, 88301, 88100',
      viewOpen: false,
      viLoading: true,
      visit: {},
      visitId: '',
      visits: [],
    }
  }

  fetchVisits = () => {
    const authToken = localStorage.getItem('AuthToken')
    axios.defaults.headers.common = { Authorization: `${authToken}` }
    this.setState({ viLoading: true })
    // need to qualify so Sarah can process IOD cases
    let url = '/api/visits/date'
    if (this.props.visitHistory === true) {
      url = '/api/visits'
    }
    axios
      .get(url)
      .then((response) => {
        this.setState({
          visits: response.data,
          viLoading: false,
        })
      })
      .catch((error) => {
				if (error.response.status === 403) {
          this.setState({ login: true })
				}
        else {
          this.setState({ viLoading: false })
          console.log(error)
        }
    })
  }

  fetchProducts = () => {
    const authToken = localStorage.getItem('AuthToken')
    axios.defaults.headers.common = { Authorization: `${authToken}` }

    this.setState({ productLoading: true })
    axios
      .get('/api/products')
      .then((response) => {
        this.setState({
          products: response.data
        })
      })
      .catch((error) => {
        if (error.response.status === 403) {
          this.setState({ login: true })
        }
        else {
          this.setState({ productLoading: false })
          console.log(error)
        }
      })
  }

  componentDidMount = () => {
    const r = refreshToken()
    if (r === 403) {
      this.setState({login:true})
    }
    else {
      this.fetchVisits()
      this.fetchProducts() 
    }
  }

  getPatient = () => {

    const visitId = this.state.selectedRows[0]
    let masterVisit = {}
    for (const visit of this.state.visits) {
      if (visitId === visit.id) {
        masterVisit = visit
        break
      }
    }
    const SAID = masterVisit.SAID

    let dateString = '';
    for (const visitId of this.state.selectedRows) {
      for (const visit of this.state.visits) {
        if (visit.id === visitId) {
          dateString = dateString + visit.date +  ', '
        }   
      }
    }
    dateString = dateString.substring(0,dateString.length - 2);
    const treatCode88100 = '88100 - R266.70 - home visit'
    const treatCode88002 = '88002 - R540.50 - consultation, wound assessment, and counseling'
    const treatCode88041 = '88041 - R205.60 - treatment of extensive wounds, charged on every visit'
    const supplRows1 = (masterVisit.home === '1') ? 
      [
        this.createData('Treatment Dates:', dateString),
        this.createData('Treatment Codes:',  treatCode88100),
        this.createData(' ',  treatCode88002),
        this.createData(' ',  treatCode88041),
        this.createData('ICD10:', 'T81.4 and Y83.8, Z48.0'),
      ] :
      [
        this.createData('Treatment Dates:', dateString),
        this.createData('Treatment Codes:',  treatCode88002),
        this.createData(' ',  treatCode88041),
        this.createData('ICD10:', 'T81.4 and Y83.8, Z48.0'),
      ]
      
    this.setState({supplRows1})
    this.setState({dateString})

    const authToken = localStorage.getItem('AuthToken')
    axios.defaults.headers.common = { Authorization: `${authToken}` }
    this.setState({paLoading:true})
    axios
      .get(`/api/patients/${SAID}`)
      .then((response) => {
        const fullName =
          response.data[0].gender +
          ' ' +
          response.data[0].firstName +
          ' ' +
          response.data[0].patientName
        this.setState({
          patient: {...response.data[0]},
          paLoading: false,
          fullName: fullName
        })
      })
      .catch((error) => {
        if (error.response.status === 403) {
          this.setState({ login: true })
        }
        else {
          this.setState({ paLoading: false })
          console.log(error)
        }
      })
  }

  fetchImages = (visitId) => {
    this.setState({ phLoading: true })
    let images = [...this.state.images]
    axios
      .get(`/api/photoBatch/${visitId}`)
      .then((response) => {
        if (response.data.length > 0) {
          for (const image of response.data)
            images.push(image)
        }
        this.setState({images})
        this.setState({phLoading: false})
      })
      .catch((error) => {
        if (error.response.status === 403) {
          this.setState({ login: true })
        }
        else {
          this.setState({ phLoading: false })
          console.log(error)
        }
      })    
  }

  getStockItems = (withPics) => {

    const authToken = localStorage.getItem('AuthToken')
    axios.defaults.headers.common = { Authorization: `${authToken}` }

    this.setState({prodList:[]})
    this.setState({sbLoading:true})
    let totals = 0
    for (const row of this.state.selectedRows) {
      const visitId = row
      if (withPics) {
        this.fetchImages(visitId)
      }
      axios  
        .get(`/api/stockBatch/${visitId}`)
        .then((response) => {
          this.setState({
            sbLoading: false,
          })
          let prodList = [...this.state.prodList]
          for (const rdata of response.data) {
            // get product details
            let found = false
            let qt = (rdata.quantity === undefined) ? 1 : rdata.quantity
            for (const pr of prodList) {
              if (rdata.product === pr.title) {
                const price = +(pr.amt / pr.quantity)
                pr.amt += price * qt
                pr.quantity += qt
                pr.amount = formatNumber(pr.amt)
                totals += price * qt
                found = true
                break
              }
            }
            if (found === false) {
              for (const prod of this.state.products) {
                if (rdata.product === prod.title) {
                  let sp = Number(`${prod.sellingPrice}`) * rdata.quantity
                  const newProd = {
                    id: rdata.id,
                    nappiCode: `${prod.nappiCode}`,
                    title: `${prod.title}`,
                    quantity: rdata.quantity,
                    amount: formatNumber(sp),
                    amt: sp
                    }
                  totals += sp
                  prodList.push(newProd)
                  break;
                }
              }
            }
          }
          this.setState({totals})
          prodList.sort (function(p1, p2) {
                  const ap1 = Number(p1.nappiCode)
                  const ap2 = Number(p2.nappiCode)
                  if (ap1 < ap2) {
                    return -1;
                  }
                  if (ap1 > ap2) {
                    return 1;
                  }
                  // names must be equal
                  return 0;
          })
          this.setState({prodList})
        })
        .catch((error) => {
          if (error.response.status === 403) {
            this.setState({ login: true })
          }
          else {
            this.setState({ sbLoading: false })
            console.log(error)
          }
        })  
    }
  }

  getAssessment = () => {
    const visitId = this.state.selectedRows[0]
    const authToken = localStorage.getItem('AuthToken')
    axios.defaults.headers.common = { Authorization: `${authToken}` }
    this.setState({paLoading:true})

    this.setState({asLoading:true})
    axios
      .get(`/api/assessment/${visitId}`)
      .then((response) => {
        let assessment = assessment0
        if (response.data.length > 0)
          assessment = response.data[0]
        this.setState({
          assessment: assessment,
          paLoading: false,
        })
      })
      .catch((error) => {
        if (error.response.status === 403) {
          this.setState({ login: true })
        }
        else {
          this.setState({ paLoading: false })
          console.log(error)
        }
      })
  }

  getTreatmentDates = () => {
  }

  handlePrint = (event) => {
    this.setState({printClicked:true})
  }

  createData = (key, value) => {
    return { key, value }
  }

  handleRemoveImage = (imageId) => {
    const images = this.state.images.filter(m => m.id !== imageId)
    this.setState({ images })
  }

  handleTotal = (event) => {
    const prodList = this.state.prodList
    prodList[`${event.target.name}`] = event.target.value
    this.setState({ prodList })
    console.log(prodList)
    let totals = 0
    for (const pr of prodList) {
      totals = pr.quantity * pr.amount
      console.log(totals)
    }
    this.setState({totals})
  }

  OpenPrintView = (mode) => {
    this.getPatient()
    this.getAssessment()
    switch (mode) {
      case AUTHORIZE:
        this.getStockItems(WITHPICS)
        this.setState({ printAuthOpen: true })
        break;
      case INVOICE:
        this.getStockItems(WITHOUTPICS)
        this.setState({ printInvoiceOpen: true })
        break;
      default:
        this.setState({ 
          printAuthOpen: false, 
          printInvoiceOpen: false, 
          selectVisitOn: false 
        })
    }
  }

  render() {
    if (this.state.login === true) {
      return <Redirect
        to={{ pathname: "/login" }}
      />
    }

    const { classes } = this.props
    const { dateString, fullName, paLoading, patient, printAuthOpen, 
      printInvoiceOpen, sbLoading, viLoading, visits } = this.state

    const Error =
      this.state.error === false ? (
        <div className={classes.subTitle}>SELECT CARE PLAN TO AUTHORIZE</div>
      ) : (
        <div className={classes.error}>Wrong Input!</div>
      )
    

    // const OpenAuthPrintView = (e) => {      
    //   this.getTreatmentDates()
    //   this.getAssessment()
    //   this.getStockItems(WITHPICS)
    //   // this.fetchImages()
    //   this.setState({ printAuthOpen: true, selectVisitOn: false })
    // }

    // const OpenInvoicePrintView = (e) => {      
    //   this.getTreatmentDates()
    //   this.getPatient()
    //   this.getAssessment()
    //   this.getStockItems(WITHOUTPICS)
    //   this.setState({ printInvoiceOpen: true, selectVisitOn: false })
    // }

    const handleVisitSelectionChange = (e) => {
      let thorough = true

      const rows = e
      for (var r1 = 0; r1 < rows.length-1 && thorough; r1++) {
        let SAID1 = ''
        const vid1 = rows[r1]
        for (const visit of visits) {
          if (vid1 === visit['id']) {
            SAID1 = visit['SAID']
            break
          }
        }

        for (var r2 = r1+1; r2 < rows.length; r2++) {
          let SAID2 = ''
          const vid2 = rows[r2]
          for (const visit of visits) {
            if (vid2 === visit['id']) {
              SAID2 = visit['SAID']
              break
            }
          }
          if (SAID1 !== SAID2) {
            thorough = false
            break
          }
        }
      }
      this.setState({ selectVisitOn: (thorough && e.length > 0) })
      this.setState({ selectedRows: e })
    }

    // const ClickToAuthVisitButton = this.state.selectVisitOn ? (
    //   <IconButton
    //     className={classes.selectButton}
    //     color="action"
    //     aria-label="Select visit"
    //     onClick={OpenAuthPrintView}
    //     size="large">
    //     <LaunchIcon style={{ fontSize: 60 }} />
    //   </IconButton>
    // ) : (
    //   ''
    // )

    // const ClickToReceiptButton = this.state.selectVisitOn ? (
    //   <IconButton
    //     className={classes.selectButton}
    //     color="action"
    //     aria-label="Select visit"
    //     onClick={OpenInvoicePrintView}
    //     size="large">
    //     <ReceiptLongIcon style={{ fontSize: 60 }} />
    //   </IconButton>
    // ) : (
    //   ''
    // )

    const ClickToAuthVisitButton = (
      <IconButton
        className={classes.selectButton}
        color="primary"
        aria-label="Select visit"
        onClick={() => this.OpenPrintView(AUTHORIZE)}
        size="large">
        <LaunchIcon style={{ fontSize: 60 }} />
      </IconButton>
    )

    const ClickToReceiptButton = (
      <IconButton
        className={classes.selectButton}
        color="primary"
        aria-label="Select visit"
        onClick={() => this.OpenPrintView(INVOICE)}
        size="large">
        <ReceiptLongIcon style={{ fontSize: 60 }} />
      </IconButton>
    )

    const handleClose = (event) => {
      this.setState({
        printAuthOpen: false,
        printInvoiceOpen: false,
        editOpen: false,
        images:[],
        prodList:[]
      })
    }

    const visitColumns = [
      {
        field: 'date',
        headerName: 'Date',
        width: 120,
        editable: false,
      },
      {
        field: 'patientName',
        headerName: 'Surname',
        width: 160,
        editable: false,
      },
      {
        field: 'firstName',
        headerName: 'First Name',
        width: 160,
        editable: false,
      },
      {
        field: 'SAID',
        headerName: 'ID/Passport',
        width: 160,
        editable: false,
      },
      {
        field: 'medicalAid',
        headerName: 'Medical Aid',
        width: 160,
        editable: false,
      },
      {
        field: 'memberNo',
        headerName: 'Membership No.',
        width: 150,
        editable: false,
      },
    ]

    const prodListColumns = [
      {
        field: 'nappiCode',
        headerName: 'Nappi Code',
        width: 180,
        editable: false,
      },
      {
        field: 'title',
        headerName: 'Materials',
        width: 500,
        editable: false,
      },
      {
        field: 'quantity',
        headerName: 'Qty',
        width: 100,
        editable: true,
      },
      {
        field: 'amount',
        headerName: 'Cost, excl. VAT',
        width: 200,
        editable: true,
      },
    ]

    const prodListColumnsInv = [
      {
        field: 'nappiCode',
        headerName: 'Nappi Code',
        width: 180,
        editable: false,
      },
      {
        field: 'title',
        fontSize: 16,
        headerName: 'Materials',
        width: 500,
        editable: false,
      },
      {
        field: 'quantity',
        headerName: 'Qty',
        width: 100,
        editable: true,
      },
      {
        field: 'amount',
        headerName: 'Cost, incl. VAT',
        width: 200,
        editable: true,
      },
    ]

    let {depth, depth2, depth3, length, length2, length3, width, width2, width3} = this.state.assessment
    let size1 = ''
    if (depth !== undefined && depth + length + width !== '') {
      depth = (depth === '') ? depth : ' x ' + depth
      width = (width === '') ? width : ' x ' + width 
      size1 = length + width + depth
    }


    let size2 = ''
    if (depth2 !== undefined && depth2 + length2 + width2 !== '') {
      depth2 = (depth2 === '') ? depth2 : ' x ' + depth2
      width2 = (width2 === '') ? width2 : ' x ' + width2 
      size2 = length2 + width2 + depth2
    }

    let size3 = ''
    if (depth3 !== undefined && depth3 + length3 + width3 !== '') {
      depth3 = (depth3 === '') ? depth3 : ' x ' + depth3
      width3 = (width3 === '') ? width3 : ' x ' + width3 
      size3 = length3 + width3 + depth3
    }

    let size = (size1 !== '') ? size1 : ''
    size = (size2 !== '') ? size + ' / ' + size2 : size
    size = (size3 !== '') ? size + ' / ' + size3 : size
     
    const postsurgical = (this.state.assessment.postsurgical ? 'Yes' : 'No')
    const infection = (this.state.assessment.infection ? 'Yes' : 'No')
    const inflammation = (this.state.assessment.inflammation ? 'Yes' : 'No')
    const supplRows2 = [
      this.createData('Medical Information:', this.state.assessment.medInfo),
      this.createData('Postsurgical:', postsurgical),
      this.createData('Infection:', infection),
      this.createData('Inflammation:', inflammation),
      this.createData('Site:', this.state.assessment.site),
      this.createData('Wound Size (cm):', size),
      this.createData('Moisture Balance:', this.state.assessment.balance),
      this.createData('Epithelial Margin:', this.state.assessment.margin),
      this.createData('Tissue Viability:', this.state.assessment.viability),
      this.createData('Texas Classification:', this.state.assessment.texas),
      this.createData('C.E.A.P:', this.state.assessment.ceap),
      this.createData('T.B.S.A:', this.state.assessment.tbsa),
    ]
    
    const fileName = patient.patientName + "_" + patient.firstName + "_" + dateString
    // const ReactToPrintStubOld = (
    //   <ReactToPrint
    //     content={() => this.componentRef}
    //     copyStyles='true'
    //     documentTitle={fileName}
    //     removeAfterPrint='true'
    //     // onAfterPrint={handleClose}
    //     trigger={() => {
    //       // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
    //       // to the root node of the returned component as it will be overwritten.
    //       return <a className={classes.a} href="#">
    //         Print PDF
    //       </a>
    //     }}
    //   />
    // )

    const ReactToPrintStub = (
      <ReactToPrint 
        content={() => this.componentRef}
        copyStyles='true'
        documentTitle={fileName}
        removeAfterPrint='true'
      >
        <PrintContextConsumer>
          {({ handlePrint }) => (
            <button 
              className={classes.b}
              onClick={handlePrint}
            >
              Print PDF
            </button>
          )}
        </PrintContextConsumer>
      </ReactToPrint>
    )

    const ImageContainer = (this.state.images.length > 0) ? ( 
      <div className={classes.imageContainer} >
        <ImageList 
          rowHeight={600} 
          className={classes.imageList} 
          cols={2}
        >
          {this.state.images.map((image) => (
            <ImageListItem key={image.id} cols={image.cols || 1}>
              <img src={image.imageUrl} alt={image.title} />
              <ImageListItemBar
                title={image.title}
                actionIcon={
                  <IconButton
                    color="action"
                    // sx={{ color: 'rgba(255, 255, 255, 0.25)' }}
                    onClick={() => this.handleRemoveImage(image.id)}                    
                  >
                    <CloseIcon />
                  </IconButton>
                }      
              />
            </ImageListItem>
          ))}
        </ImageList>
      </div>
    ) : ''

    const CompanyDetailsHeader = 
    (
      <div style={{ width: '20%' }}>
        <center>
          <Typography variant="h3" noWrap>
          <b>Sr Mary Ann Bernon</b>
          </Typography>
          <Typography variant="h6" noWrap>
            Practice no: 0616257
          </Typography>
          < hr/>
          <Box className={classes.letterHead}>
            <p>Tel: 079 272 7425</p>
            <p>Email: admin@maryannbernon.org</p> 
          </Box>
        </center>
      </div>
    )

    const AuthorizeRequestHeader = 
    (
      <div style={{marginLeft:'120px', fontSize: '11pt', padding: "6px 0px 6px 16px"}}>
        <h1>Authorization Request for wound care</h1>
        <h3>to: {patient.medicalAid}</h3>
        <h3>Att: Pre-Authorization</h3>
        <h2>
          Please note wound care was required for the following
          patient:
        </h2>
        <h3>Name: {fullName}</h3>
        <h3>Contact Number: {patient.mobile}</h3>
        <h3>Member Number: {patient.memberNo}</h3>
        <h3>ID: {patient.SAID}</h3>
        <h3>
          Referring Doc:{' '}{patient.referringDoctor}{'  '}
          {patient.referringDrNo}
        </h3>
      </div>
    )

    const InvoiceHeader = 
    (
      <div  contentEditable={true} suppressContentEditableWarning={true}>
        <article className={classes.article0}>
          <Grid container item xs={12} spacing={0} >
            <Grid className={classes.upAndLeft} item xs={10}>
              <Stack>
                <h2>Consultus Nursing Services</h2>
                <h3>Practice no: 0616257</h3>                
              </Stack>
            </Grid>
            <Grid className={classes.upAndLeft} item xs={2}>
              <Logo/>
            </Grid>
          </Grid>
          <Grid container item xs={12} spacing={0} >
            <Grid className={classes.upAndLeft} item xs={12}>
              <b>"Health on 202"</b>
            </Grid>
            <Grid className={classes.upAndRight} item xs={12}>
              Office no: 0310013129
            </Grid>
            <Grid className={classes.upAndLeft} item xs={8}>
              202 Lena Ahrens Road
            </Grid>
            <Grid className={classes.upAndRight} item xs={4}>
              Cellphone: 0792727425
            </Grid>
            <Grid className={classes.upAndLeft} item xs={8}>
              Durban 4001
            </Grid>
            <Grid className={classes.upAndRight} item xs={4}>
              Email: admin@maryannbernon.org
            </Grid>
          </Grid>
        </article>
        <article className={classes.article2}>          
          <Grid container item xs={12} spacing={0}>
            <Grid className={classes.upAndBold} item xs={4}>
              Tax Invoice
            </Grid>
            <Grid className={classes.upAndRight} item xs={8}>
              Invoice Date: {dateString}
            </Grid>
            <Grid className={classes.upAndBold} item xs={12}>
              {patient.firstName} {patient.patientName}
            </Grid>
            <Grid className={classes.upAndRight} item xs={12}>
              Medical Aid: {patient.medicalAid}
            </Grid>
            <Grid className={classes.upAndRight} item xs={12} editable>
              Member No: {patient.memberNo}
            </Grid>
          </Grid>
        </article>
        <article className={classes.article1}>
          <br />
          {/* <br /> */}
          <span>
            Patient: {patient.firstName} {patient.patientName}, 
            d.o.b: {patient.dateOfBirth}, Dep Code: {patient.dependentCode}
          </span>
          <Divider style={{ background: 'black' }} variant="left"  />
          <span className={classes.finePrint}> 
            Treating Provider: <b>Mary Ann Bernon</b>, Council Number 13181029, Date of Service: {dateString}<br/>
          </span>
          <span className={classes.finePrint}>
            ICD10: T81.4, Y83.8, Z48.0
          </span>
          <br />
          <br />
        </article>
      </div>
    )

    const InvoiceBottom = (
      <article className={classes.article3}> 
        <Grid className={classes.finePrint2} container item xs={12} spacing={0}>
          <Grid item xs={2}>
            Bank Details
          </Grid>
          <Grid item xs={10}>
            <span>
            <b>Consultus Nursing Services, Nedbank,</b> Branch <b>198765, </b>
               Account no: <b>1145721206,</b> Account Type: <b>Transmission</b>
            </span>
          </Grid>
          <Divider/>
          <Grid item xs={2}>
            Company Info
          </Grid>
          <Grid item xs={10}>
            VAT No:  <b>4710280647</b>
          </Grid>
          <Divider/>
          <Grid item xs={2}>
            EFT Info
          </Grid>
          <Grid item xs={10}>
            <span>
              Payment ref: <b>{patient.firstName} {patient.patientName}</b>; send Proof of Payment to: admin@maryannbernon.org
            </span>
          </Grid>
        </Grid>
      </article>
    )

    const TreatmentCodes =
    (
      <div>
      <Table className={classes.table} size="small">
        <TableHead>
          </TableHead>
        <TableBody>
          {this.state.supplRows1.map((row) => (
          <TableRow key={row.key}>
            <TableCell
              style={{width: "130pt"}} 
              component="th" 
              scope="row"
            >
              {row.key}
            </TableCell>
            <TableCell contentEditable={true} suppressContentEditableWarning={true}>
              {row.value}
            </TableCell>
          </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
    )

    const pl = this.state.prodList.length
    const ah = 736 // 16 lines * 46 px
    let rah = Math.trunc(ah / pl)
    let raheight = rah > 46 ? 46 : rah
    let aheight = raheight * pl
    let fheight = aheight < 369 ? 0 : (ah - aheight + 200) // up to 8 products should suppress page break

    const pb = <div style={{ width: 1000, height: fheight }} ></div>
    const ListOfProductsUsedAuth =
    (
      <div style={{marginLeft:'60px'}}>
        <div style={{ width: 1000, height: aheight, }}>
          <DataGrid
            className={classes.prodGrid}
            rows={this.state.prodList}
            columns={prodListColumns}
            rowHeight={raheight}
            autoHeight='true'
            hideFooter='true'
            showCellRightBorder='true'
            showColumnRightBorder='true'
          />
        </div>
        {pb}
      </div>
    )

    const iheight = 790
    let rih = Math.trunc(iheight / this.state.prodList.length)
    const riheight = rih > 46 ? 46 : rih -1

    let ListOfProductsUsedInv =
    (
      <div style={{marginLeft:'80px'}}>
        <div style={{ height: iheight }}>
          <DataGrid
            className={classes.prodGridInv}
            rows={this.state.prodList}
            columns={prodListColumnsInv}
            rowHeight={riheight}
            autoHeight='true'
            hideFooter='true'
            showCellRightBorder='true'
            showColumnRightBorder='true'
          />
        </div>
      </div>
    )
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'ZAR',
    })

    const SumTotal = this.state.totals > 0 ?
    (      
      <article 
        className={classes.article4}
      > 
          <Grid container item xs={12} spacing={0}>
            <Grid className={classes.upAndBold} item xs={9}>
              Totals
            </Grid>
            <Grid className={classes.upAndUnderline} item xs={3} contentEditable={true} suppressContentEditableWarning={true}>
              {formatter.format(this.state.totals)}
            </Grid>
          </Grid>
      </article>
    ) : ''

    const Supplements = 
    (
      <div>
        <Table className={classes.table} size="small">
          <TableHead>
            </TableHead>
          <TableBody>
            {supplRows2.map((row) => (
            <TableRow key={row.key}>
              <TableCell
                style={{width: "130pt"}} 
                component="th" 
                scope="row"
              >
                {row.key}
              </TableCell>
              <TableCell contentEditable={true} suppressContentEditableWarning={true}>
                {row.value}
              </TableCell>
            </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    )

    const AuthAppBar =
    (
      <AppBar position="static">
        <Toolbar variant="dense">
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
            size="large">
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.poptitle}>
            {'Print View'}
          </Typography>
          {ReactToPrintStub}
        </Toolbar>
      </AppBar>
    )

    const AuthDialog = 
    (
      <Dialog
        fullScreen
        open={printAuthOpen}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        {AuthAppBar}
        <div
            style={{ height: 1500, width: '500%' }}
            ref={(el) => (this.componentRef = el)}
        >
          <p/>
          {CompanyDetailsHeader}
          {( sbLoading ) && (
            <CircularProgress size={150} className={classes.uiProgess} />
          )}
          {AuthorizeRequestHeader}
          {TreatmentCodes}
          {ListOfProductsUsedAuth}
          {Supplements}
          {ImageContainer}
        </div>
      </Dialog>
    )

    const InvoiceDialog = 
    (
      <Dialog
        fullScreen
        open={printInvoiceOpen}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        {AuthAppBar}
        <div
            style={{ height: 1500, width: '500%' }}
            ref={(el) => (this.componentRef = el)}
        >
          <p/>
          {InvoiceHeader}
          {( sbLoading ) && (
            <CircularProgress size={150} className={classes.uiProgess} />
          )}
          {!(paLoading) && ListOfProductsUsedInv}
          {SumTotal}
          {InvoiceBottom}
        </div>
      </Dialog>
    )

    const AuthEntryGrid =
    (
      <div style={{ width: '740pt', height: '480pt' }}>
        <DataGrid
          className={classes.visitGrid}
          rows={this.state.visits}
          columns={visitColumns}
          isRowSelectable={() => true}
          checkboxSelection
          onSelectionModelChange={handleVisitSelectionChange}
          // disableSelectionOnClick
          // onRowEnter={fetchKey}
          // onRowDoubleClick={OpenAuthPrintView}
        />
      </div>
    )

    const SubTitle = this.state.selectVisitOn ? (
      <Grid container item xs={12} spacing={0}>
        <Grid item xs={6}>
          {Error}
        </Grid>
        <Grid item xs={2}>
          {ClickToAuthVisitButton}
        </Grid>
        <Grid item xs={1}>
          {ClickToReceiptButton}
        </Grid>
      </Grid>
    ) : (
      <Grid container item xs={12} spacing={1}>
        <Grid item xs={8}>
          {Error}
        </Grid>
      </Grid>
    )

    if ( viLoading ) {
      return (
        <main className={classes.content}>
          <div className={classes.toolbar} />
          {( viLoading ) && (
            <CircularProgress size={150} className={classes.uiProgess} />
          )}
        </main>
      )
    } else {
      return (
        <main className={classes.content}>
          <div className={classes.toolbar} />
          {SubTitle}
          {/* ### DataGrid to display visits to be authorized */}
          {AuthEntryGrid}
          {AuthDialog}
          {InvoiceDialog}
        </main>
      );
    }


    // if ( viLoading ) {
    //   return (
    //     <main className={classes.content}>
    //       <div className={classes.toolbar} />
    //       {( viLoading ) && (
    //         <CircularProgress size={150} className={classes.uiProgess} />
    //       )}
    //     </main>
    //   )
    // } else {
    //   return (
    //     <main className={classes.content}>
    //       <div className={classes.toolbar} />
    //       {Error}
    //       {/* ### DataGrid to display visits to be authorized */}
    //       {AuthEntryGrid}
    //       <div>
    //         {ClickToAuthVisitButton}
    //         {ClickToReceiptButton}
    //       </div>
    //       {AuthDialog}
    //       {InvoiceDialog}
    //     </main>
    //   );
    // }
  }
}

export default withStyles(styles)(authorize)
