import React, { Component } from 'react';
import axios from 'axios';


import Account from '../components/account';
import Authorize from '../components/authorize';
import Patient from '../components/patient';
import Product from '../components/product';
import Layout from '../components/layout';
import Report from '../components/report';
import Todo from '../components/todo';
import Visit from '../components/visit';



import {
  AppBar,
  Avatar,
  CircularProgress,
	CssBaseline,
  Divider,
	Drawer,
  IconButton,
	List,
	ListItemButton,
	ListItemIcon,
	ListItemText,
  Toolbar,
  Typography,
} from '@material-ui/core'

import withStyles from '@material-ui/styles/withStyles';

import AccountBoxIcon from '@material-ui/icons/AccountBox';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CloseIcon from '@material-ui/icons/Close';
import CollectionsIcon from '@material-ui/icons/Collections';
// import FlareIcon from '@material-ui/icons/Flare';
import MenuIcon from '@material-ui/icons/Menu';
import NotesIcon from '@material-ui/icons/Notes';
import PersonIcon from '@material-ui/icons/Person';
import StorageIcon from '@material-ui/icons/Storage';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import { authMiddleWare } from '../util/auth'


const ACCOUNT = 1;
const ASSESSMENT = 2;
const AUTHORIZE = 3;
const LAYOUT = 4;
const PATIENT = 5;
const PRODUCT = 6;
const TODO = 7;
const VISIT = 8;
const REPORT = 9;
const HISTORY = 10;



const drawerWidth = 240;

const styles = (theme) => ({
	root: {
		display: 'flex'
	},
	appBar: {
		zIndex: theme.zIndex.drawer + 1
	},
	submitButton: {
    display: 'block',
    color: 'white',
    textAlign: 'center',
    position: 'absolute',
    top: 14,
    right: 10,
  },
	drawer: {
		width: drawerWidth,
		flexShrink: 0
	},
	drawerPaper: {
		width: drawerWidth
	},
	content: {
		flexGrow: 1,
		padding: theme.spacing(3)
	},
	avatar: {
		height: 110,
		width: 100,
		flexShrink: 0,
		flexGrow: 0,
		marginTop: 20
	},
	uiProgess: {
		position: 'fixed',
		zIndex: '1000',
		height: '31px',
		width: '31px',
		left: '50%',
		top: '35%'
	},
	toolbar: theme.mixins.toolbar,
});

class home extends Component {
	state = {
		render: false
	};

	loadAccountPage = (event) => {
		this.setState({ render: ACCOUNT,
										anchor: false 
									});
	};

  loadAssessPage = (event) => {
		this.setState({ render: ASSESSMENT,
			anchor: false 
		});
	};

  loadAuthPage = (event) => {
		this.setState({ render: AUTHORIZE,
			anchor: false 
		});
	};

	loadVHistPage = (event) => {
		this.setState({ render: HISTORY, visitHistory:true,
			anchor: false 
		});
	};

	loadLayoutPage = (event) => {
		this.setState({ render: LAYOUT,
			anchor: false 
		});
	};

	loadPatientPage = (event) => {
		this.setState({ render: PATIENT,
			anchor: false 
		});
	};

  loadProductPage = (event) => {
		this.setState({ render: PRODUCT,
			anchor: false 
		});
	};

  loadReportPage = (event) => {
		this.setState({ render: REPORT,
			anchor: false 
		});
	};

	loadTodoPage = (event) => {
		this.setState({ render: TODO,
			anchor: false 
		});
	};

	loadVisitPage = (event) => {
		this.setState({ render: VISIT, visitHistory:false,
			anchor: false 
		});
	};

	logoutHandler = (event) => {
		localStorage.removeItem('AuthToken');
		localStorage.removeItem('TokenExpiry');
		this.props.history.push('/login');
	};

	constructor(props) {
		super(props);

		this.state = {
			anchor: false,
			firstName: '',
			imageLoading: false,
			lastName: '',
			profilePicture: '',
			uiLoading: true,
			username: '',
			visitHistory: false,			
		};
	}

	componentDidMount = () => {
		authMiddleWare(this.props.history);
		const authToken = localStorage.getItem('AuthToken');
		axios.defaults.headers.common = { Authorization: `${authToken}` };
		axios
			.get('/api/user')
			.then((response) => {
				let visitHistory = response.data.userCredentials.history
				if (visitHistory === undefined)
					visitHistory = false
				this.setState({
					firstName: response.data.userCredentials.firstName,
					lastName: response.data.userCredentials.lastName,
					email: response.data.userCredentials.email,
					phoneNumber: response.data.userCredentials.phoneNumber,
					country: response.data.userCredentials.country,
					username: response.data.userCredentials.username,
					visitHistory: visitHistory,
					uiLoading: false,
					profilePicture: response.data.userCredentials.imageUrl
				});
			})
			.catch((error) => {
				if(error.response.status === 403) {
					localStorage.removeItem('AuthToken');
					this.props.history.push('/login')
				}
				console.log(error);
				this.setState({ errorMsg: 'Error in retrieving the data' });
			});
	};

	toggleDrawer = () => {
		const anchor = (!this.state.anchor);
		this.setState({anchor});
	}
	render() {
		const { classes } = this.props;
		const { visitHistory } = this.state;

//		const { classes, anchor } = this.props;	
//		const toggleDrawer = () => this.setState({anchor:!anchor})
	
    let focus = null;
		const username = this.state.username === '' ? "dummy" : this.state.username
    switch (this.state.render) {
      case ACCOUNT:
        focus = <Account />;
        break;
      // case ASSESSMENT:
      //   focus = <Assessment />;
      //   break;
      case AUTHORIZE:
        focus = (
					<div>
						<Authorize visitHistory={visitHistory} />
					</div>
				)
        break
      case HISTORY:
        focus = <Visit username={username} visithistory={true} />;
        break;
      case LAYOUT:
        focus = <Layout />;
        break;
			case PATIENT:
				focus = <Patient />;
				break;
			case PRODUCT:
        focus = <Product />;
        break;
      case REPORT:
        focus = <Report />;
        break;
      case TODO:
        focus = <Todo />;
        break;
      case VISIT:
        focus = <Visit username={username} visithistory={false} />;
        break;
      default:
      	focus = <Visit username={username} visithistory={false}/>;
    } 
		if (this.state.uiLoading === true) {
			return (
				<div className={classes.root}>
					{this.state.uiLoading && <CircularProgress size={150} className={classes.uiProgess} />}
				</div>
			);
		} else {
			return (
				<div className={classes.root}>
					<CssBaseline />
					<AppBar position="fixed" className={classes.appBar}>
						<Toolbar>
							<IconButton
                                color="inherit"
                                aria-label="Menu"
                                onClick={this.toggleDrawer}
                                size="large">
								<MenuIcon />
							</IconButton>
							<Typography variant="h6" noWrap>
								ConsultUs
							</Typography>
						</Toolbar>
					</AppBar>
					<Drawer
						className={classes.drawer}
						variant="temporary"
						open={this.state.anchor} 
						classes={{
							paper: classes.drawerPaper
						}}
					>
						<List>
							<ListItemButton key="Close" onClick={this.toggleDrawer}>
								<ListItemIcon>
									<CloseIcon />
								</ListItemIcon>
							</ListItemButton>
						</List>
						<Divider />
						<center>
							<Avatar src={this.state.profilePicture} className={classes.avatar} />
							<p>
								{' '}
								{this.state.firstName} {this.state.lastName}
							</p>
						</center>
						<Divider />
						<List>
							<ListItemButton key="Auth" onClick={this.loadAuthPage}>
								<ListItemIcon>
									{' '}
									<CheckCircleIcon />{' '}
								</ListItemIcon>
								<ListItemText primary="Authorizations" />
							</ListItemButton>

							<ListItemButton key="Visit" onClick={this.loadVisitPage}>
								<ListItemIcon>
									{' '}
									<StorageIcon />{' '}
								</ListItemIcon>
								<ListItemText primary="Visits" />
							</ListItemButton>

							<ListItemButton key="Report" onClick={this.loadReportPage}>
								<ListItemIcon>
									{' '}
									<SaveAltIcon />{' '}
								</ListItemIcon>
								<ListItemText primary="Reports" />
							</ListItemButton>

							<ListItemButton key="Patient" onClick={this.loadPatientPage}>
								<ListItemIcon>
									{' '}
									<PersonIcon />{' '}
								</ListItemIcon>
								<ListItemText primary="Patients" />
							</ListItemButton>

							<ListItemButton key="Product" onClick={this.loadProductPage}>
								<ListItemIcon>
									{' '}
									<CollectionsIcon />{' '}
								</ListItemIcon>
								<ListItemText primary="Products" />
							</ListItemButton>

							<ListItemButton key="Visit (History)" onClick={this.loadVHistPage}>
								<ListItemIcon>
									{' '}
									<StorageIcon />{' '}
								</ListItemIcon>
								<ListItemText primary="Visits (hist.)" />
							</ListItemButton>

							<ListItemButton key="Todo" onClick={this.loadTodoPage}>
								<ListItemIcon>
									{' '}
									<NotesIcon />{' '}
								</ListItemIcon>
								<ListItemText primary="Scribbles" />
							</ListItemButton>

							<ListItemButton key="Account" onClick={this.loadAccountPage}>
								<ListItemIcon>
									{' '}
									<AccountBoxIcon />{' '}
								</ListItemIcon>
								<ListItemText primary="Account" />
							</ListItemButton>

							 {/* <ListItemButton key="Layout" onClick={this.loadLayoutPage}>
								<ListItemIcon>
									{' '}
									<FlareIcon />{' '}
								</ListItemIcon>
								<ListItemText primary="Layout tests" />
							</ListItem>
 */}
							<ListItemButton key="Logout" onClick={this.logoutHandler}>
								<ListItemIcon>
									{' '}
									<ExitToAppIcon />{' '}
								</ListItemIcon>
								<ListItemText primary="Logout" />
							</ListItemButton>
						</List>
					</Drawer>

					<div>{focus}</div>
				</div>
            );
		}
	}
}

export default withStyles(styles)(home);